@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

@font-face {
  font-family: "TheSeasons";
  src: url("./fonts/theseasons-reg.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BDScript";
  src: url("./fonts/BDScript-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "EyesomeScript";
  src: url("./fonts/EyesomeScript.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #faf7f4;
}

/* h1 {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: #274035;
}

p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #706e67;
} */
